import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { generatePublicImgURL } from "../../../urlConfig";
import { BsGrid3X3Gap } from "react-icons/bs";
import Slider from "react-slick";
import { ADMIN, ICONS_AND_PHOTOS } from "../utils/constants/s3Constants";

const ImageSection = ({ images, profId }) => {

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });

  //   react slick settings
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="container mx-auto mb-2 ">
      <div className="relative  m-auto  flex  lg:mx-[10em] mt-8">
        {isDesktopOrLaptop && images.length > 0 && images.length > 5 && (
          <div className="gallery grid  aspect-w-18 ">
            <figure className="gallery__item gallery__item--1 ">
              <img
                src={generatePublicImgURL(
                  "serviceprofessionals/" + images?.[0]?.img
                )}
                alt="Gallery image 1"
                className="gallery__img rounded-tl-lg"
              />
            </figure>
            <figure className="gallery__item gallery__item--2">
              <img
                src={generatePublicImgURL(
                  "serviceprofessionals/" + images?.[1]?.img
                )}
                alt="Gallery image 2"
                className="gallery__img "
              />
            </figure>
            <figure className="gallery__item gallery__item--3">
              <img
                src={generatePublicImgURL(
                  "serviceprofessionals/" + images?.[2]?.img
                )}
                alt="Gallery image 3"
                className="gallery__img rounded-r-lg "
              />
            </figure>
            <figure className="gallery__item gallery__item--4">
              <img
                src={generatePublicImgURL(
                  "serviceprofessionals/" + images?.[3]?.img
                )}
                alt="Gallery image 4"
                className="gallery__img rounded-bl-lg"
              />
            </figure>
            <figure className="gallery__item gallery__item--5">
              <img
                src={generatePublicImgURL(
                  "serviceprofessionals/" + images?.[4]?.img
                )}
                alt="Gallery image 5"
                className="gallery__img"
              />
            </figure>
          </div>
        )}
        {
          (images && (isTabletOrMobile && images.length > 0) ||
            (isDesktopOrLaptop && images.length > 0 && images.length < 5)) && (
            <div>
              <figure className="gallery__item gallery__item--4">
                <div>
                  <img
                    src={generatePublicImgURL("serviceprofessionals/" + images?.[0]?.img)}
                    key={images}
                    alt="Gallery image 4"
                    className="gallery__img "
                  />
                </div>
              </figure>
            </div>
          )
        }

        {
          images && images.length > 0 && (
            <Link
              to={{
                pathname: "/image-gallery",
                state: { images: images, profId: profId },
              }}
            >
              <button className="absolute text-white font-extrabold  blue-gradient top-7 p-2 rounded-md right-5 flex gap-1 items-center cursor-pointer">
                View Gallery
                <BsGrid3X3Gap />
              </button>
            </Link>
          )
        }
      </div>
    </div>
  );
};

export default ImageSection;
