import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import store from './store';
import { Provider } from 'react-redux';
import MobileFooterNavItems from './components/aNewGoToUI/layout/MobileFooterNavItems';
import NavItems from './components/aNewGoToUI/layout/NavItems';
import DesktopFooter from './components/aNewGoToUI/layout/DesktopFooter';

ReactDOM.render(
  <React.StrictMode>
  <div className='min-h-screen flex flex-col'>
    <Provider store={store}>
      <Router>
        <NavItems />
         <div className='flex-grow'>
           <App />
         </div>

          {
           typeof window !== 'undefined' && typeof window.innerWidth === 'number' && window.innerWidth < 1224 && <MobileFooterNavItems />
          }
          {
           typeof window !== 'undefined' && typeof window.innerWidth === 'number' && window.innerWidth > 1224 && <DesktopFooter />
          }
          
      </Router>

    </Provider>
    </div>
  </React.StrictMode>,
  document.getElementById('root')
);

