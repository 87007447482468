import React from 'react'
import Header from './Header'
import GoToServices from './GoToServices'
import goToServiceList from '../utils/configs/goToServices'
import TopBanners from './TopBanners'
import HomeStaysAndTourismBanner from './HomeStaysAndTourismBanner'
import { useMediaQuery } from 'react-responsive'
import DesktopHeroSection from './DesktopHeroSection'
import DesktopBanners from './DesktopBanners'
import MobileTopBannerCard from './MobileTopBannerCard'

const Home = () => {

  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 })
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 })

  return (
    <>
      {/* <Header/> */}
      {
        isDesktopOrLaptop && (
          <>
            <DesktopHeroSection />
             <MobileTopBannerCard
              heading="Start Selling Your Services !!"
              subHeading="Become The Service Provider At BharatPros And Start Earning Online."
              image="RegisterBannerIcon.png"
            />
            <DesktopBanners />
          </>
        )
      }
      {
        isTabletOrMobile && (
          <>
            {/* <TopBanners /> */}
            <MobileTopBannerCard
              heading="Start Selling Your Services !!"
              subHeading="Become The Service Provider At BharatPros And Start Earning Online."
              image="RegisterBannerIcon.png"
            />
            <HomeStaysAndTourismBanner />
          </>
        )
      }
      <GoToServices goToServiceList={goToServiceList} />
    </>
  )
}

export default Home