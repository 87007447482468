export const SELECT_LOCATION = "Select Location"
export const PROFILE_ACTIONS = "/profile-actions"
export const REGISTER_ACTION = "/add-service"
export const HOME = "/"
export const LOCATIONS = [
    "Chandigarh",
    "Kharar",
    "Mohali",
    "Zirakpur",
    "Panchkula",
    "Shahpur",
    "Gaggal",
    "Gaggal Airport",
    "Dharmshala",
    "Tanda",
    "Palampur",
    "McLeod Ganj",
    "Dharmkot",
    "Kangra",
    "Bilaspur",
    "Mandi",
    "Bhuntar",
    "Bir",
    "Kasol",
    "Tosh Valley (Kasol)",
    "Dalhousie",
    "Kullu",
    "Manali",
    "Una",
    "Solan",
    "Shimla",
    "Pathankot",
    "Delhi",
    "Noida",
    "Gurugram",
    "Bengaluru",
    "Pune",
    "Hyderabad",
    "Surat",
    "Ahmedabad",
    "Faridabad",
    "Jammu",
    "Mumbai"
]