import React from 'react'
import { getLocationByServiceNameWeServe } from '../configs/serviceLocationsWeServe'
import { SELECT_LOCATION } from '../constants/CommonConstants'
import Search from './Search'

const SearchLayout = (props) => {

    const { serviceName, handleLocationSelect, handleLocationCancel } = props

    return (
        <div className="flex justify-center sticky">
            <Search
                input={getLocationByServiceNameWeServe(serviceName)}
                searchCategory={`${SELECT_LOCATION} For ${serviceName}`}
                onLocationSelect={handleLocationSelect}
                onCancel={handleLocationCancel}
            />
        </div>
    )
}

export default SearchLayout