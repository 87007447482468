import React from 'react'
import { FaLocationDot } from "react-icons/fa6";
import { GrLocation } from "react-icons/gr";
import { CiLocationOn } from "react-icons/ci";

const AddressInImageCards = (props) => {
    const { address, location } = props;
    return (
        <div className='flex items-center gap-2'>
            <span className='text-[2.5em] stroke-1'>
                <CiLocationOn />
            </span>
            <div className='flex flex-col flex-wrap'>
                {
                    location?.length > 0 && (
                        <p className="text-md  text-black font-bold">
                            {location?.[0]}
                        </p>
                    )
                }
                {
                    address &&
                    <p className="text-[0.8em] text-gray-500">
                        {address.pincodeData?.poName} &nbsp; {address.pincodeData?.cityBlock}
                        &nbsp; {address.pincodeData?.stateName}  &nbsp; {address.pincodeData?.pincode}
                    </p>
                }
            </div>


        </div>
    )
}

export default AddressInImageCards