import React from "react";
import { InvoiceProfessionalPdfFooter } from "../../workerCategory/AddWorkerCallback";
import { checkAdminRole, executeForAdminOrSupportRoles } from "../../../utils/checkRole";
import { hasUserOrAdminAccess, isProfessionalUserAuthenticated } from "../common/Common";

export function DownLoadProfInvoice(prof) {
    return prof?.paymentStatus === "success" ?
        <InvoiceProfessionalPdfFooter prof={prof} />
        : null
}

export function checkToShowAddProfessionalDetailsButton(professional, auth) {
    return (checkIfPropertiesPresent(professional)) && hasUserOrAdminAccess(professional, auth);
}

export function checkIfPropertiesPresent(professional) {
    return professional?.servicePropertyFields
        || professional?.choiceOfService?.properties;
}
