import React from 'react'
import ServiceWrapper from './ServiceWrapper'
import SearchLayout from './SearchLayout'
import ServiceLayout from './ServiceLayout'
import Spinner from '../../../layout/Spinner'
import MobileTopBannerCard from '../../home/MobileTopBannerCard'

const CustomServiceComponent = (props) => {
    const { serviceName, handleLocationSelect, handleLocationCancel, professional, professionals, cardType } = props
    return (
        <>
            <MobileTopBannerCard
                heading="Get What You Need"
                subHeading="Quickly Book the Service Provider from Choosing Among the Best"
                image="RegisterBannerIcon.png"
            />
            <ServiceWrapper>
                <SearchLayout
                    serviceName={serviceName}
                    handleLocationSelect={handleLocationSelect}
                    handleLocationCancel={handleLocationCancel}
                />
                {
                    professional.loading ? (
                        // <Spinner />
                        <>
                        </>
                    ) : (
                        <ServiceLayout
                            professionals={professionals}
                            cardType={cardType}
                        />
                    )
                }
            </ServiceWrapper>
        </>
    )
}

export default CustomServiceComponent