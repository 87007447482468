import { ProfessionalConstants } from "../actions/types";

const initialState = {
    professionals: [],
    professional: [],
    error: null,
    loading: false,
};

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case ProfessionalConstants.ADD_PROFESSIONAL_REQUEST:
            return {
                ...state,
                loading: true,
                professionals: [],
                professional: [],
            }
        case ProfessionalConstants.ADD_PROFESSIONAL_REQUEST:
            return {
                ...state,
                loading: false
            }
        case ProfessionalConstants.GET_PROFESSIONALS_REQUEST:
            return {
                ...state,
                loading: true,
                professionals: [],
                professional: [],
            };
        case ProfessionalConstants.GET_PROFESSIONALS_SUCCESS:
            return {
                ...state,
                professionals: payload.professionals,
                loading: false
            };
        case ProfessionalConstants.GET_PROFESSIONALS_FAILURE:
            return {
                ...initialState,
                loading: false,
                error: payload.errors
            }
        case ProfessionalConstants.GET_PROFESSIONAL_REQUEST:
            return {
                ...state,
                loading: true,
                professional: []
            };
        case ProfessionalConstants.GET_PROFESSIONAL_SUCCESS:
            return {
                ...state,
                professional: payload.professional,
                loading: false
            };
        case ProfessionalConstants.GET_PROFESSIONAL_FAILURE:
            return {
                ...initialState,
                loading: false,
                error: payload.errors
            }
        case ProfessionalConstants.GET_PROFESSIONAL_STATUS_REQUEST:
            return {
                ...state,
                loading: true,
                professional: []
            };
        case ProfessionalConstants.GET_PROFESSIONAL_STATUS_SUCCESS:
            return {
                ...state,
                professional: payload.professional,
                loading: false
            };
        case ProfessionalConstants.GET_PROFESSIONAL_STATUS_FAILURE:
            return {
                ...initialState,
                loading: false,
                error: payload.errors
            }
        default:
            return state;
    }
}