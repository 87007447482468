import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { GrLocation } from "react-icons/gr";
import { MdLocalPhone } from "react-icons/md";
import { FaCircleInfo } from "react-icons/fa6";
import { generatePublicImgURL } from "../../../urlConfig";
import { ADMIN, HOME_PAGE_ICON_PHOTOS, ICONS_AND_PHOTOS } from "../utils/constants/s3Constants";
import { useLoginPhoneUtils } from "../utils/common/loginPhoneUtils";
import AddressInImageCards from "../utils/common/AddressInImageCards";
import MobileTopBannerCard from "../home/MobileTopBannerCard";

const ProfileCard = ({ professional }) => {
  const { handleCallClick, renderLoginModal } = useLoginPhoneUtils();

  return (
    <div className="flex flex-col">
      <div className=" bg-black/5 border border-black/10 shadow-sm rounded-lg mx-3 my-3 lg:my-0 lg:pr-3">
        <div
          className={`flex flex-row  gap-[0.9em] lg:gap-[1.5em] lg:px-3 lg:py-4 justify-evenly  lg:mx-3 items-center   p-2 w-full  `}
        >
          <div className=" ">
            <div className="w-[7.5em] h-[7.5em] rounded-full">
              <img
                className=" object-fit rounded-lg "
                width="100%"
                height="100%"
                src={generatePublicImgURL(ADMIN + '/' + HOME_PAGE_ICON_PHOTOS + '/' + 'DefaultProfile.png')}
                alt="im"
              />
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <div>
              <div className=" text-start ">
                <p className="text-[1.7em]  font-bold">
                  {professional.firmName
                    ? professional.firmName
                    : professional.address.name}{" "}
                </p>
              </div>
              <div className="text-black/80 flex items-start gap-1 mt-3 justify-start ">
                <span className="">
                  <GrLocation />
                </span>
                <p className="text-[0.7em]">
                  {professional.address.pincodeData.cityBlock}{" "}
                  {professional.address.pincodeData.district}{" "}
                  {professional.address.pincodeData.stateName}
                  &nbsp; ({professional.address.pincodeData.pincode})
                </p>
              </div>
            </div>
            <div className="flex justify-evenly w-full  gap-1 ">
              <button
                className="z-30 flex  items-center justify-center items-center bg-black rounded-full text-white py-1 px-4 "
                onClick={() => handleCallClick(professional.address.mobile)}
              >
                <MdLocalPhone className="text-white" />
                <span className="text-[0.7em] ml-1 ">Call Now</span>
              </button>
              <Link to={`/profile/${professional.userId}`}>
                <span className=" right-2 z-30 flex  items-center bg-black justify-center  items-center rounded-full text-white py-1 px-4  ">
                  <FaCircleInfo className="text-white" />
                  <span className="text-[0.7em] ml-1 ">View Profile</span>
                </span>
              </Link>
            </div>
          </div>
        </div>
        {renderLoginModal()}
      </div>
    </div>
  );
};

export default ProfileCard;
