import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { generatePublicImgURL } from "../../../urlConfig";
import { RxCross2 } from "react-icons/rx";
import { IoChevronBackCircleSharp } from "react-icons/io5";

const ImageGallery = () => {
  const location = useLocation();
  const images = location.state ? location.state.images : null;
  const profId = location.state ? location.state.profId : null;
  const [selectedImage, setSelectedImage] = useState({ img: "", index: 0 });

  const viewImage = (img, index) => {
    setSelectedImage({ img, index });
  };

  const closeFullView = (img, index) => {
    setSelectedImage({ img: "", index });
  };

  return (
    <>
      {selectedImage.img && (
        <div
          style={{
            width: "100%",
            height: "100%",
            background: "black",
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
          }}
          className="relative"
        >
          <span className="text-white font-white cursor-pointer text-4xl absolute top-3 right-3" onClick={closeFullView}>
            <RxCross2 />
          </span>
          <img
            src={selectedImage.img}
            alt="img"
            style={{ width: "auto", maxWidth: "95%", maxHeight: "95%" }}
          />
        </div>
      )}

      <div className="p-3 md:w-[60em] lg:w-[80em] m-auto">
        <Link
          to={`/show-service-details/${profId}`}
        >
          <div className="text-3xl py-2"><IoChevronBackCircleSharp /></div>
        </Link>
        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 2, 750: 2, 900: 3 }}>
          <Masonry gutter="5px">
            {images?.map((image, index) => (
              <img
                key={index}
                log
                style={{ width: "100%", display: "block" }}
                alt={index}
                src={generatePublicImgURL("serviceprofessionals/" + image?.img)}
                className="cursor-pointer"
                onClick={() =>
                  viewImage(
                    generatePublicImgURL("serviceprofessionals/" + image?.img),
                    index
                  )
                }
              />
            ))}
          </Masonry>
        </ResponsiveMasonry>
      </div>
    </>
  );
};

export default ImageGallery;
