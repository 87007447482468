import React from 'react'
import NavItems from './NavItems'
import Home from '../home/Home'

const Landing = () => {
    return (
        <div className='w-full flex flex-col item-center justify-center'>
            <Home />
        </div>
    )
}

export default Landing