import React from "react";

const ServiceDescription = ({ name, description }) => {
  return (
    <div className="lg:mx-[20em] ml-4">
      <p className="text-3xl font-extrabold mb-4">{name}</p>
      <p className="mb-6 text-gray-500 lg:font-semibold md:font-bold ">
        {description}
      </p>
    </div>
  );
};

export default ServiceDescription;
